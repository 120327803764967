<template>
    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="bg-gray-100 h-full">
        <div class="flex-1 overflow-auto focus:outline-none" tabindex="0">
            <main class="flex-1 relative pb-8 z-0 overflow-y-auto">
                <!-- Page header -->
                <div class="bg-white shadow-md rounded-b-3xl">
                    <div
                        class="px-4 sm:px-6 lg:max-w-6xl xl:max-w-full lg:mx-auto lg:px-8"
                    >
                        <div
                            class="pb-6 md:flex md:items-center md:justify-between"
                        >
                            <div class="flex-1 flex min-w-0">
                                <!-- Profile -->
                                <div
                                    class="flex flex-1 border rounded-md items-center"
                                >
                                    <div>
                                        <div class="flex items-center">
                                            <h1
                                                class="ml-3 text-xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate"
                                            >
                                                {{ $t("dashboard.welcome") }}
                                                {{ user.given_name }}
                                            </h1>
                                        </div>
                                        <dl
                                            class="flex flex-col ml-3 sm:flex-row sm:flex-wrap"
                                        >
                                            <dd
                                                class="flex items-center text-lg text-gray-500 font-bold capitalize"
                                            >
                                                {{ user.enterprise }}
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                                <div
                                    class="w-1/5 desktop:w-1/6 mr-3 opacity-0"
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="!isLoading">
                    <div
                        v-if="data.alert.requests.request_number > 0"
                        class="m-4 text-left mx-8 p-4 shadow-md bg-yellow-50 rounded-md flex justify-between"
                        :class="{ hidden: isCancelled1 }"
                    >
                        <div>
                            <span class="text-yellow-900 text-md font-bold">
                                <span v-if="this.$i18n.locale === 'en'">
                                    {{ data.alert.requests.request_number }}
                                    requests for Over-the-Limit spending are
                                    received from
                                    {{ data.alert.requests.request_user }}
                                    users.</span
                                >
                                <span v-if="this.$i18n.locale === 'zh'">
                                    已收到
                                    {{ data.alert.requests.request_number }}
                                    個超出限額支出申請。</span
                                >
                                <button
                                    class="ml-3 underline font-bold"
                                    @click="
                                        this.$router.push({ name: 'Approvals' })
                                    "
                                >
                                    {{ $t("dashboard.recent_view_details") }} →
                                </button>
                            </span>
                            <br />
                            <br />
                            <div
                                v-for="(alert, index) in this.data.alert
                                    .requests.details"
                                class="text-orange-700 text-sm font-medium"
                                :key="index"
                            >
                                {{ alert }}
                            </div>
                        </div>
                        <div>
                            <button
                                class="mr-2 text-yellowbrown-700 hover:text-yellowbrown-900"
                                @click="isCancelled1 = true"
                            >
                                ✕
                            </button>
                        </div>
                    </div>
                    <div
                        v-if="this.data.alert.requests.low_balance === 1"
                        class="m-4 text-left mx-8 p-4 shadow-md bg-yellow-50 rounded-md flex justify-between"
                        :class="{ hidden: isCancelled2 }"
                    >
                        <div>
                            <div class="text-yellow-900 text-md font-bold">
                                {{ $t("dashboard.low_alert_title") }}
                            </div>
                            <br />
                            <span class="text-orange-700 text-sm font-medium">
                                {{ $t("dashboard.low_alert_content1") }}
                                <button
                                    @click="
                                        this.$router.push({ name: 'TopUp' })
                                    "
                                    class="underline font-bold"
                                >
                                    {{ $t("dashboard.low_alert_content2") }}
                                </button>
                            </span>
                        </div>
                        <div>
                            <button
                                class="mr-2 text-yellow-900 hover:text-yellow-600"
                                @click="isCancelled2 = true"
                            >
                                ✕
                            </button>
                        </div>
                    </div>
                    <div
                        v-if="this.data.alert.requests.exhausted_balance === 1"
                        class="m-4 text-left mx-8 p-4 shadow-md bg-yellow-50 rounded-md flex justify-between"
                        :class="{ hidden: isCancelled3 }"
                    >
                        <div>
                            <div class="text-yellow-900 text-md font-bold">
                                {{ $t("dashboard.exhausted_alert_title") }}
                            </div>
                            <br />

                            <span class="text-orange-700 text-sm font-medium">
                                {{ $t("dashboard.exhausted_alert_content1") }}

                                <button
                                    @click="
                                        this.$router.push({ name: 'TopUp' })
                                    "
                                    class="underline font-bold"
                                >
                                    {{
                                        $t("dashboard.exhausted_alert_content2")
                                    }}
                                </button>
                            </span>
                        </div>
                        <div>
                            <button
                                class="mr-2 text-yellow-900 hover:text-yellow-600"
                                @click="isCancelled3 = true"
                            >
                                ✕
                            </button>
                        </div>
                    </div>
                </div>
                <div v-if="!isLoading" class="mt-8">
                    <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                        <h2
                            class="text-lg leading-6 font-medium text-left text-gray-900"
                        >
                            {{ $t("dashboard.overview") }}
                        </h2>
                        <div
                            class="mt-2 grid grid-cols-1 gap-14 desktop:gap-24 sm:grid-cols-2 lg:grid-cols-3"
                        >
                            <!-- Card -->

                            <div
                                class="bg-white overflow-hidden shadow-lg rounded-lg"
                            >
                                <div class="p-5">
                                    <div class="flex items-center">
                                        <div class="ml-5 w-0 flex-1">
                                            <dl>
                                                <dt
                                                    class="text-sm font-medium text-gray-500 truncate"
                                                >
                                                    {{
                                                        $t(
                                                            "dashboard.account_bal"
                                                        )
                                                    }}
                                                </dt>
                                                <dd>
                                                    <div
                                                        v-if="!isLoading"
                                                        class="text-lg font-medium text-gray-900"
                                                    >
                                                        ${{
                                                            data.overview
                                                                .balance
                                                        }}
                                                    </div>
                                                </dd>
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                                <div class="bg-gray-50 px-5 py-3">
                                    <div class="text-sm text-left">
                                        <button
                                            @click="switchPage('TopUp')"
                                            class="font-medium text-cyan-700 hover:text-cyan-900"
                                        >
                                            {{ $t("dashboard.topup") }}
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <!-- More items... -->
                            <div
                                class="bg-white overflow-hidden shadow-lg rounded-lg"
                            >
                                <div class="p-5">
                                    <div class="flex items-center">
                                        <div class="ml-5 w-0 flex-1">
                                            <dl>
                                                <dt
                                                    class="text-sm font-medium text-gray-500 truncate"
                                                >
                                                    {{
                                                        $t(
                                                            "dashboard.booking_today"
                                                        )
                                                    }}
                                                </dt>
                                                <dd>
                                                    <div
                                                        v-if="!isLoading"
                                                        class="text-lg font-medium text-gray-900"
                                                    >
                                                        {{
                                                            data.overview
                                                                .today_booking
                                                        }}
                                                    </div>
                                                </dd>
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                                <div class="bg-gray-50 px-5 py-3">
                                    <div class="text-sm text-left">
                                        <button
                                            @click="
                                                this.$router.push('Bookings')
                                            "
                                            class="font-medium text-cyan-700 hover:text-cyan-900"
                                        >
                                            {{ $t("dashboard.view_details") }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="bg-white overflow-hidden shadow-lg rounded-lg"
                            >
                                <div class="p-5">
                                    <div class="flex items-center">
                                        <div class="ml-5 w-0 flex-1">
                                            <dl>
                                                <dt
                                                    class="text-sm font-medium text-gray-500 truncate"
                                                >
                                                    {{
                                                        $t(
                                                            "dashboard.booking_month"
                                                        )
                                                    }}
                                                </dt>
                                                <dd>
                                                    <div
                                                        v-if="!isLoading"
                                                        class="text-lg font-medium text-gray-900"
                                                    >
                                                        {{
                                                            data.overview
                                                                .month_booking
                                                        }}
                                                    </div>
                                                </dd>
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                                <div class="bg-gray-50 px-5 py-3">
                                    <div class="text-sm text-left">
                                        <button
                                            @click="
                                                this.$router.push('Bookings')
                                            "
                                            class="font-medium text-cyan-700 hover:text-cyan-900"
                                        >
                                            {{ $t("dashboard.view_details") }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bg-white m-4 lg:m-8 pb-8 rounded-2xl shadow-md">
                        <div class="pt-8 flex justify-between">
                            <h2
                                class="max-w-6xl ml-2 lg:ml-0 lg:-mx-5 text-left text-xl leading-6 font-bold text-gray-900 sm:px-6 lg:px-8"
                            >
                                {{ $t("dashboard.recent_bookings") }}
                            </h2>
                            <button
                                type="button"
                                class="mr-5 rounded-md text-cyan-700 hover:text-cyan-900"
                                @click="this.$router.push('bookings')"
                            >
                                {{ $t("dashboard.recent_view_details") }}
                            </button>
                        </div>
                        <!-- Activity table (small breakopoint and up) -->
                        <div class="block">
                            <div class="mx-auto">
                                <div class="flex flex-col mt-2">
                                    <div
                                        class="min-w-full border-b overflow-scroll"
                                    >
                                        <table
                                            class="table-auto min-w-full divide-y divide-gray-200"
                                        >
                                            <thead>
                                                <tr>
                                                    <th
                                                        class="whitespace-nowrap py-5 pl-4 bg-gray-50 text-left text-sm border-r text-gray-500 tracking-wider"
                                                    >
                                                        {{
                                                            $t(
                                                                "dashboard.username"
                                                            )
                                                        }}
                                                    </th>
                                                    <th
                                                        class="whitespace-nowrap py-5 pl-4 bg-gray-50 text-left text-sm border-r text-gray-500 tracking-wider"
                                                    >
                                                        {{
                                                            $t(
                                                                "dashboard.spending_plan"
                                                            )
                                                        }}
                                                    </th>
                                                    <th
                                                        class="whitespace-nowrap pl-4 py-2 bg-gray-50 text-left text-sm text-gray-500 border-r  tracking-wider"
                                                    >
                                                        {{
                                                            $t(
                                                                "dashboard.venue"
                                                            )
                                                        }}
                                                    </th>
                                                    <th
                                                        class="whitespace-nowrap pl-4 py-2 bg-gray-50 text-left text-sm text-gray-500 border-r  tracking-wider"
                                                    >
                                                        {{
                                                            $t(
                                                                "dashboard.booking_type"
                                                            )
                                                        }}
                                                    </th>
                                                    <th
                                                        class="whitespace-nowrap pl-4 py-2 bg-gray-50 text-left text-sm text-gray-500 border-r  tracking-wider"
                                                    >
                                                        {{
                                                            $t(
                                                                "dashboard.booked_time"
                                                            )
                                                        }}
                                                    </th>
                                                    <th
                                                        class="whitespace-nowrap pl-4 py-2 bg-gray-50 text-left text-sm text-gray-500 border-r  tracking-wider"
                                                    >
                                                        {{
                                                            $t(
                                                                "dashboard.check-in"
                                                            )
                                                        }}
                                                    </th>
                                                    <th
                                                        class="whitespace-nowrap pl-4 py-2 bg-gray-50 text-left text-sm text-gray-500 border-r tracking-wider"
                                                    >
                                                        {{
                                                            $t(
                                                                "dashboard.check-out"
                                                            )
                                                        }}
                                                    </th>
                                                    <th
                                                        class="whitespace-nowrap pl-4 py-2 bg-gray-50 text-left text-sm text-gray-500 border-r tracking-wider"
                                                    >
                                                        {{
                                                            $t(
                                                                "dashboard.hours_booked"
                                                            )
                                                        }}
                                                    </th>
                                                    <th
                                                        class="whitespace-nowrap pr-4 px-8 py-2 bg-gray-50 text-right text-sm text-gray-500 tracking-wider"
                                                    >
                                                        {{
                                                            $t(
                                                                "dashboard.amount"
                                                            )
                                                        }}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody
                                                class="bg-white divide-y divide-gray-200"
                                            >
                                                <tr
                                                    class="bg-white text-left font-medium text-tiny"
                                                    v-for="(booking,
                                                    index) in this.data
                                                        .reservation"
                                                    :key="index"
                                                >
                                                    <td
                                                        class="border-r px-4 py-3"
                                                    >
                                                        {{ booking.username }}
                                                        <div>
                                                            {{ booking.email }}
                                                        </div>
                                                    </td>
                                                    <td class="border-r px-4">
                                                        {{ booking.plan_name }}
                                                    </td>
                                                    <td class="border-r px-4">
                                                        {{ booking.space }}
                                                    </td>
                                                    <td class="border-r px-4">
                                                        {{ booking.book_type }}
                                                    </td>
                                                    <td class="border-r px-4">
                                                        {{ booking.book_time }}
                                                    </td>
                                                    <td class="border-r px-4">
                                                        {{ booking.check_in }}
                                                    </td>
                                                    <td class="border-r px-4">
                                                        {{ booking.check_out }}
                                                    </td>
                                                    <td class="border-r px-4">
                                                        {{
                                                            booking.duration_hr
                                                        }}
                                                    </td>
                                                    <td class="text-right pr-4">
                                                        {{ booking.amount }}
                                                    </td>
                                                </tr>

                                                <!-- More items... -->
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-if="isLoading"
                    class="flex justify-center mt-20 items-center"
                >
                    <div class="w-1/5">
                        <Spinner />
                    </div>
                </div>
            </main>
        </div>
    </div>
</template>

<script>
import Spinner from "@/components/Spinner"
export default {
    name: "Dashboard",
    components: { Spinner },
    props: {
        user: { required: true, type: Object },
        enterprise: { required: false }
    },
    methods: {
        switchPage(pageName) {
            if (pageName === "Logout") {
                this.$auth.logout({})
            } else {
                this.$router.push({ name: pageName })
            }
        },
        async getData() {
            const token = await this.$auth.getTokenSilently()
            let url = ""
            if (this.user.role === 1) {
                url =
                    process.env.VUE_APP_API_BASE_URL +
                    `/index/dashboard?user_id=${this.$auth.user.value.sub}&lang=${this.$i18n.locale}&enterprise_id=${this.enterprise}`
            } else {
                url =
                    process.env.VUE_APP_API_BASE_URL +
                    `/index/dashboard?user_id=${this.$auth.user.value.sub}&lang=${this.$i18n.locale}`
            }
            this.$axios
                .get(url, {
                    headers: {
                        Authorization: `Bearer ${token}` // send the access token through the 'Authorization' header
                    }
                })
                .then(response => {
                    this.data = response.data.Data
                    setTimeout(() => {
                        this.isLoading = false
                    }, 500)
                })
        }
    },
    mounted() {
        this.getData()
    },
    watch: {
        enterprise(to, from) {
            if (this.user.role === 1) {
                this.getData()
            }
        }
    },
    data() {
        return {
            isLoading: true,
            data: {},
            isCancelled1: false,
            isCancelled2: false,
            isCancelled3: false
        }
    }
}
</script>

<style scoped></style>
